import axios from 'axios'

const subscribeNewsletter = input => {
  return axios.post('https://support-api.azurewebsites.net/api/teams/newsletter', input, {
    headers: {
      'content-type': 'application/json'
    }
  })
}

const supportQuery = input => {
  return axios.post('https://support-api.azurewebsites.net/api/teams/query', input, {
    headers: {
      'content-type': 'application/json'
    }
  })
}

const teamsHooks = {
  subscribeNewsletter,
  supportQuery
}

export default teamsHooks
