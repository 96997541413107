import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import logoImg from '../../assets/images/logos/logo-c.svg'

const myQuery = graphql`
  {
    allStrapiServices(limit: 7, sort: { fields: order }) {
      nodes {
        title
        slug
        id
      }
    }
    allStrapiCaseStudies(limit: 7) {
      nodes {
        id
        headerTitle
        slug
        isReleased
        releasedUrl
      }
    }
  }
`

const Navbar = () => {
  const [collapse, setCollapse] = useState(false)
  const handleCollapse = () => {
    setCollapse(!collapse)
  }
  const {
    allStrapiServices: { nodes: services },
    allStrapiCaseStudies: { nodes: products },
  } = useStaticQuery(myQuery)

  return (
    <div className="navbar-area sticky-top">
      <div className="desktop-nav nav-area">
        <div className="container-fluid">
          <div className="container-max">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link className="navbar-brand" to="/">
                <img src={logoImg} alt="Logo" width="80px" height="80px" />
              </Link>

              <button
                className={`navbar-toggler 
                                navbar-toggler-right 
                                ${collapse ? 'collapsed' : 'show'}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                onClick={handleCollapse}
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar" />
                <span className="icon-bar middle-bar" />
                <span className="icon-bar bottom-bar" />
              </button>

              <div
                className={`collapse navbar-collapse 
                                ${collapse ? 'show' : ''}`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav m-auto {navbarStyles.navbar-nav}">
                  <li className="nav-item">
                    <Link to="/" className="nav-link" activeClassName="active">
                      Home
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/products" className="nav-link" activeClassName="active">
                      Products
                      <i className="bx bx-caret-down" />
                    </Link>
                    <ul className="dropdown-menu">
                      {products.map((product, index) => {
                        return (
                          <li key={index} className="nav-item">
                            {product.isReleased ? (
                              <a
                                href={product.releasedUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {product.headerTitle}
                              </a>
                            ) : (
                              <Link
                                to={`/products/${product.slug}`}
                                className="nav-link"
                                activeClassName="active"
                              >
                                {product.headerTitle}
                              </Link>
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link to="/services" className="nav-link" activeClassName="active">
                      Services
                      <i className="bx bx-caret-down" />
                    </Link>

                    <ul className="dropdown-menu">
                      {services.map((servicescard, index) => {
                        return (
                          <li key={index} className="nav-item">
                            <Link
                              to={`/services/${servicescard.slug}`}
                              className="nav-link"
                              activeClassName="active"
                            >
                              {servicescard.title}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link to="/careers" className="nav-link" activeClassName="active">
                      Careers
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/contact" className="nav-link" activeClassName="active">
                      Contact Us
                    </Link>
                  </li>
                </ul>

                <div className="nav-side d-display nav-side-mt">
                  {/* <div className="nav-side-item">
                    <div className="search-side-widget">
                      <form className="search-side-form">
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search..."
                        />
                        <button type="submit">
                          <i className="bx bx-search"></i>
                        </button>
                      </form>
                    </div>
                  </div> */}

                  <div className="nav-side-item">
                    <div className="get-btn">
                      <Link to="/contact" className="default-btn btn-bg-two border-radius-50">
                        Request Demo <i className="bx bx-chevron-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
