/**
 *
 * @param {*} string
 * @returns returns true if string is 'Not Null & Not Empty & Not Whitespace'
 */
const isNotNullOrEmptyOrWhiteshace = string => {
  return string !== undefined && string !== null && string !== ''
}

const isNotNullUndefinedOrEmpty = prop => {
  return !!(prop !== undefined && prop !== null && prop !== '')
}

const convertToCamelCase = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const IsTrue = value => {
  return !!(value !== undefined && value === true)
}

const isNotMoreThen = prop => {
  let count = 0
  prop.map(data => {
    return (count += parseInt(data.percentage))
  })

  return !(count > 100)
}

const IsDecimal = value => {
  return !value.toString().includes('.')
}

const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const getFormattedDate = unformatedDate => {
  const date = new Date(unformatedDate)
  const year = date.getFullYear()

  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : '0' + month

  let day = date.getDate().toString()
  day = day.length > 1 ? day : '0' + day

  return day + '-' + month + '-' + year
}

function getFormatedAMPMTime (date) {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

const getFromattedDateTime = unformatedDateTime => {
  const dateObj = new Date(unformatedDateTime)
  const date = getFormattedDate(dateObj)
  const time = getFormatedAMPMTime(dateObj)
  return date + ' ' + time
}

const getCompleteUrl = resourceUrl => {
  return resourceUrl
}

const capitalizeFirstLetter = data => {
  const input = data.toLowerCase()
  return input.charAt(0).toUpperCase() + input.slice(1)
}

const isInViewport = element => {
  try {
    const rect = element.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  } catch (error) {
    return false
  }
}

const getFileExtention = fileName => {
  try {
    return fileName.substring(fileName.lastIndexOf('.') + 1)
  } catch {
    return 'png'
  }
}

const getScaledImage = (imagePath, size, isHeightScale = true) => {
  // c_scale,w_181
  // https://res.cloudinary.com/innoage/image/upload/v1653031836/Product_Page_Banners_rugs_32f0835bb3.jpg
  // https://res.cloudinary.com/innoage/image/upload/c_scale,w_181/v1653031836/Product_Page_Banners_rugs_32f0835bb3.jpg
  try {
    const delimeter = 'upload/'
    const beforeUpload = imagePath.split(delimeter)[0]
    const afterUpload = imagePath.split(delimeter)[1]
    if (isHeightScale) {
      const insertSection = `upload/c_scale,h_${size}/`
      return beforeUpload + insertSection + afterUpload
    } else {
      const insertSection = `upload/c_scale,w_${size}/`
      return beforeUpload + insertSection + afterUpload
    }
  } catch {
    return imagePath
  }
}

export {
  isNotNullUndefinedOrEmpty,
  convertToCamelCase,
  IsTrue,
  isNotMoreThen,
  randomIntFromInterval,
  IsDecimal,
  getFromattedDateTime,
  getCompleteUrl,
  capitalizeFirstLetter,
  isInViewport,
  getFileExtention,
  isNotNullOrEmptyOrWhiteshace,
  getScaledImage
}
