import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { isNotNullOrEmptyOrWhiteshace } from '../../helper/utilities'

const myQuery = graphql`
  {
    strapiTopHeader {
      title
      icon
      callBtn
      callBtnUrl
      socialLink {
        id
        icon
        iconUrl
      }
    }
  }
`

const TopHeader = () => {
  const {
    strapiTopHeader: { title, callBtn, callBtnUrl, socialLink, icon },
  } = useStaticQuery(myQuery)

  return (
    <header className="top-header top-header-bg">
      <div className="container-fluid">
        <div className="container-max">
          <div className="row align-items-center">
            <div className="col-lg-7 col-sm-6">
              <div className="top-head-left">
                <div className="top-contact">
                  <h3>
                    {isNotNullOrEmptyOrWhiteshace(icon) && <i className={icon} />}
                    {isNotNullOrEmptyOrWhiteshace(title) && title} &nbsp;
                    <a href={callBtnUrl}>{callBtn}</a>
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-sm-6">
              <div className="top-header-right">
                <div className="top-header-social">
                  <ul>
                    {socialLink.map(social => {
                      return (
                        <li key={social.id}>
                          <a href={social.iconUrl} target="_blank" rel="noreferrer">
                            <i className={social.icon} />
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>

                {/* <div className="language-list">
                  English&nbsp;&nbsp;
                  <img
                    src="https://res.cloudinary.com/innoage/image/upload/v1653152209/india_z1vfes.svg"
                    alt=""
                    height="15px"
                  />
                   <ul className='language-menu'>
                    <li>
                      <Link className='language-item' to='#'>
                        Coming in multiple languages soon*
                      </Link>
                    </li>
                  </ul> 
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default TopHeader
