import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

import { dayFormat, monthFormat } from '../../helper/dateFormat'
import teamsHooks from '../../services/teams.service'
import { getScaledImage } from '../../helper/utilities'

const myQuery = graphql`
  {
    allStrapiBlog(limit: 3, sort: { fields: publishedOn, order: DESC }) {
      nodes {
        id
        blogImg {
          url
        }
        title
        publishedOn
        slug
      }
    }
    strapiFooter {
      title
      helpText
      talkBtn
      talkBtnUrl
      shortDese1
      shortDesc2
      copyrightText
      copyrightBtn
      copyrightBtnUrl
      image {
        url
      }
    }
  }
`
const Footer = () => {
  const [newsletterEmail, setnewsletterEmail] = React.useState('')
  const [subscribed, setSubscribed] = React.useState(false)
  const {
    allStrapiBlog: { nodes: blogs },
    strapiFooter: {
      title,
      helpText,
      talkBtn,
      talkBtnUrl,
      shortDese1,
      shortDesc2,
      copyrightText,
      image,
    },
  } = useStaticQuery(myQuery)

  const sendNotification = async () => {
    const input = {
      title: 'Subscibe to Newsletter',
      message: newsletterEmail,
    }
    teamsHooks.subscribeNewsletter(input).then(
      () => {
        setSubscribed(true)
      },
      () => {
        setSubscribed(true)
      },
    )
  }

  return (
    <footer className="footer-area footer-bg">
      <div className="container">
        <div className="footer-top pt-100 pb-70">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="footer-widget">
                <div className="footer-logo align-items-center">
                  <Link to="/">
                    <img
                      src={image.url}
                      alt="Footer Logo"
                      height="70px"
                      // width="140px"
                    />
                  </Link>
                </div>
                <p>{shortDese1}</p>
                <div className="footer-call-content">
                  <h3>{helpText}</h3>
                  <span>
                    <a href={talkBtnUrl}>{talkBtn}</a>
                  </span>
                  <i className="bx bx-headphone" />
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-sm-6">
              <div className="footer-widget pl-2">
                <h3>Quick Links</h3>
                <ul className="footer-list">
                  <li>
                    <Link to="/faq">
                      <i className="bx bx-chevron-right" />
                      FAQ's
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-condition">
                      <i className="bx bx-chevron-right" />
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      <i className="bx bx-chevron-right" />
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/blogs">
                      <i className="bx bx-chevron-right" />
                      Our Blogs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget pl-5">
                <h3>Our Blogs</h3>
                <ul className="footer-blog">
                  {blogs.map(blogcard => {
                    return (
                      <li key={blogcard.id}>
                        <Link to={`/blogs/${blogcard.slug}`}>
                          <img
                            src={getScaledImage(blogcard.blogImg.url, 90)}
                            alt="Footer Blog"
                            width="100%"
                          />
                        </Link>
                        <div className="content">
                          <h3>
                            <Link to={`/blogs/${blogcard.slug}`}>{blogcard.title}</Link>
                          </h3>
                          <div className="datelist">
                            <h3>{dayFormat(blogcard.publishedOn)}</h3>
                            <span>{monthFormat(blogcard.publishedOn)}</span>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget">
                <h3>{title}</h3>
                <p>{shortDesc2}</p>
                <div className="newsletter-area">
                  <form className="newsletter-form" data-toggle="validator">
                    {subscribed ? (
                      <div className="col-lg-12">
                        <div class="alert alert-success" role="alert">
                          Thanks for Subscribing to our Newsletter
                        </div>
                      </div>
                    ) : (
                      <>
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Enter Your Email"
                          name="EMAIL"
                          value={newsletterEmail}
                          onChange={e => setnewsletterEmail(e.target.value)}
                          required
                        />
                        <button
                          className="subscribe-btn"
                          type="button"
                          disabled={!newsletterEmail}
                          onClick={() => {
                            sendNotification()
                          }}
                        >
                          <i className="bx bx-paper-plane" />
                        </button>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copy-right-area">
          <div className="copy-right-text">
            <p>
              {copyrightText}
              {/* <a href={copyrightBtnUrl} target="_blank"> {copyrightBtn}</a>  */}
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
