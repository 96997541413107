/**
 *
 * @param {*} date
 * @returns day like 11
 */
export const dayFormat = (date) => {
  return new Date(date).toLocaleDateString('en-US', { day: 'numeric' })
}

/**
 *
 * @param {*} date
 * @returns returns month like Dec
 */
export const monthFormat = (date) => {
  return new Date(date).toLocaleDateString('en-US', { month: 'short' })
}

/**
 *
 * @param {*} date
 * @returns returns year like 1991
 */
export const yearFormat = (date) => {
  return new Date(date).getFullYear()
}
